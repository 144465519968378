<!-- 企业挂牌展示 -->
<template>
	<div>
		<scNav navTag="area" :themeColor="themeColor"></scNav>
		<!-- 面包屑 -->
		<div style="max-width: 1440px;margin: 0 auto;">
			<crumbs :crumbs="['首页','企业专区']"></crumbs>
		</div>

		<div class="sc-width">
			<enterprise @clkItem="clkItem">
				<!-- 分类 -->
				<rqqy :themeColor="themeColor" :list="rqqy.data"></rqqy>
				<div class="block">
				    <el-pagination
						background
						:page-size="parseInt(rqqy.per_page)"
						layout="total, prev, pager, next, jumper"
						:total="rqqy.total"
						@current-change="curChge">
				    </el-pagination>
				</div>
				<div class="block2">
					<phonePg></phonePg>
				</div>
			</enterprise>
		</div>
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import enterprise from "@/components/enterprise/enterprise"
	import phonePg from "@/components/phonePg/phonePg"
	import scNav from '@/components/special/scNav'
	import rqqy from '@/components/special/rqqy'
	export default {
		data() {
			return {
				themeColor: "",
				type:0, // 筛选类型
				catid:0,
				page:1,
				plate:0, // 所属模块
				industry:0, // 行业分类
				capital:0, // 注册资本,
				city:0, // 所在城市
				rqqy:{}
			}
		},
		created() {
			let type = this.$route.query.type
			this.type = type
			if (type == 1) {
				// 专精特新版
				
				this.themeColor = "#386eb8"
				this.catid = 118
			} else if (type == 2) {
				// 科技创新版	
				this.themeColor = "#47a8c8"
				this.catid = 127
			} else if (type == 3) {
				// 交易版
				this.themeColor = "#d3a21e"
				this.catid = 135
			}
			this.getList()
		},
		components: {
			phonePg,
			enterprise,
			scNav,
			rqqy
		},
		methods: {
			// 点击分类进入详情页
			clkEntAil(index) {
				this.$router.push({
					name: "areaDail"
				})
			},
			// 获取列表
			getList() {
				this.$axios.get("Specialization/getEnterpriseList",{
					params:{
						catid:this.catid,
						limit:10,
						page:this.page,
						plate:this.plate,
						industry:this.industry,
						capital:this.capital,
						city:this.city
					}
				}).then(res=>{
					if(res.code) {
						this.rqqy = res.data
					}
				})
			},
			// 点击每一项
			clkItem(index,id) {
				if(index==0) {
					this.plate = id
				}else if(index==1) {
					this.industry = id
				}else if(index==2) {
					this.capital = id
				}else if(index==3) {
					this.city = id
				}
				this.getList()
			},
			// 分页改变
			curChge(e) {
				this.page = e
				this.getList()
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .search2 {
		padding: 10px 0;
		.all {
			.el-dropdown-link {
				font-size: 14px;
			}
		}
		.saix {
			.el-dropdown-link {
				font-size: 14px;
			}
		} 
	}
	/deep/ .type {
		margin-top: 10px;
	}
	.sc-width {
		margin-bottom: 30px;
		padding-bottom: 0;
	}
</style>
